import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useMemo } from "react";

type StripeElementsWrapperProps = React.PropsWithChildren & {
  connectedAccountId: string;
};

export const StripeElementsWrapper = ({
  connectedAccountId,
  children,
}: StripeElementsWrapperProps) => {
  if (
    !import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY ||
    !connectedAccountId ||
    connectedAccountId.length < 1
  ) {
    throw new Error("Missing stripe publishable key or connected account id");
  }

  // Memoize this promise so that as this component re-renders, the stripe
  // promise must never re-load
  const stripePromise = useMemo(
    () =>
      loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY, {
        stripeAccount: connectedAccountId,
      }),
    [connectedAccountId],
  );

  return (
    <Elements
      options={{
        fonts: [
          {
            cssSrc:
              "https://fonts.googleapis.com/css2?family=Figtree:wght@400;500;600&display=swap",
          },
        ],
      }}
      stripe={stripePromise}
    >
      {children}
    </Elements>
  );
};
