import { Link } from "iconoir-react";
import { addHttpIfNeeded } from "../../../utils/isUrl";

export type InputLinkProps = {
  url: string;
};

export const InputLink = (props: InputLinkProps) => {
  const { url } = props;
  return (
    <a href={addHttpIfNeeded(url)} target="_blank">
      <Link />
    </a>
  );
};
