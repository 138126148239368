import { Space } from "core/components/shared/Space";
import { Flex } from "styled-system/jsx";
import { useAppContext } from "core/components/apps/PreferredLayout";
import { parseToUTCDate } from "core/utils/parseToUTCDate";
import {
  CustomLearnerActivityLogFilter,
  SortFindManyLearnerActivityLogInput,
  useGetLearnerActivityLogManyQuery,
} from "gql/generated";
import { useEffect, useState } from "react";
import { DATE_PATTERN_SIMPLE, formatDate } from "core/utils/formatDate";
import { ExportToCSV } from "core/components/shared/ExportToCSV";
import { Tag, Text } from "@ttc3k/trekker";
import { List } from "./components/List";
import { FilterMenu } from "./components/FilterMenu";
import { useFilter } from "./useFilter";
import { Headers } from "./constants";
import {
  ButtonTagVariant,
  LegacyButton,
  LegacyLoadingSpinner,
  Sizes,
} from "@ttc3k/ttc-design-system";
import { Hashtag, MagicWand } from "iconoir-react";
import { WhereaboutsModal } from "core/components/shared/WhereaboutsModal";
import GenerateInsightsModal from "apps/library/learner/spaces/stats/components/GenerateInsightsModal.tsx";
import { useAuth0 } from "@auth0/auth0-react";

function convertToCSV(data) {
  return (
    data
      ?.map(item => {
        return [
          formatDate(item.createdDateISO, "dd mm yyyy"),
          item.courseName,
          item.personName,
          item.operatorName,
          item.eventType,
        ].filter(value => value !== null && value !== undefined);
      })
      .filter(subArray => subArray.length > 0) ?? []
  );
}

export default function Stats() {
  const { setActionSection, setTitle, setDescription } = useAppContext();
  const { filter, onResetFilter, onUpdateFilter } = useFilter();
  const { user } = useAuth0();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const filterEndDate =
    filter.createdAt.end.length > 0
      ? parseToUTCDate(filter.createdAt.end)
      : undefined;
  filterEndDate?.setUTCDate(filterEndDate.getDate());
  filterEndDate?.setUTCHours(23, 59, 59, 999);
  const filterStartDate =
    filter.createdAt.start.length > 0
      ? parseToUTCDate(filter.createdAt.start)
      : undefined;
  filterStartDate?.setUTCDate(filterStartDate.getDate());
  filterStartDate?.setUTCHours(0, 0, 0, 0);

  const customFilterPayload: CustomLearnerActivityLogFilter = {
    search: filter.searchParams.length > 1 ? filter.searchParams : undefined,
    createdEndDate: filterEndDate ? filterEndDate.toISOString() : undefined,
    createdStartDate: filterStartDate
      ? filterStartDate.toISOString()
      : undefined,
    status: filter.status ? filter.status : undefined,
  };

  const { data, loading, error, fetchMore } = useGetLearnerActivityLogManyQuery(
    {
      variables: {
        filter: {
          _custom: {
            ...customFilterPayload,
          },
          tenantsOf: user?.whereaboutsTenants,
        },
        perPage: 50,
        sort: SortFindManyLearnerActivityLogInput.CreatedDesc,
      },
      fetchPolicy: "cache-and-network",
    },
  );

  const onPageChange = (newPage: number) => {
    fetchMore({
      variables: {
        page: newPage,
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prevResult;
        return fetchMoreResult;
      },
    });
  };

  useEffect(() => {
    setTitle("Stats");
    setDescription("The activity log for all learners at your DMO");
  }, [setDescription, setTitle]);

  const colHeaders = Headers.map(o => o.label);

  useEffect(() => {
    setActionSection(
      <ExportToCSV
        fileName={`stats-${formatDate(new Date(), DATE_PATTERN_SIMPLE)}`}
        colHeaders={Headers.map(o => o.label)}
        data={convertToCSV(data?.learnerActivityLogMany?.items)}
      />,
    );
    return () => setActionSection(undefined);
  }, [data?.learnerActivityLogMany?.items, setActionSection]);

  return (
    <Space>
      <Flex flexDir={"column"} gap={"400"}>
        <FilterMenu
          updateFilter={onUpdateFilter}
          resetFilter={onResetFilter}
          filter={filter}
        />
        <Flex
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          gap="5"
        >
          <Tag>Results: {data?.learnerActivityLogMany?.count}</Tag>
          <LegacyButton
            size={Sizes.Small}
            variant={ButtonTagVariant.Unstyled}
            color={"#7979EC"}
            iconStart
            icon={<MagicWand fontSize={14} />}
            onClick={() => setIsModalOpen(true)}
          >
            <span>Generate Insights with AI...</span>
          </LegacyButton>
        </Flex>
        <List
          loading={loading}
          error={error}
          data={data}
          onPageChange={onPageChange}
        />
      </Flex>
      <GenerateInsightsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        report={convertToCSV(data?.learnerActivityLogMany?.items)}
        headers={colHeaders}
      />
    </Space>
  );
}
