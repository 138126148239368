import {
  Controller,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useParams } from "react-router";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import {
  ButtonTagVariant,
  LegacyButton,
  LegacyCheckbox,
  LegacyDropdown,
  LegacySectionHeader,
  LegacyTag,
  LegacyToast,
  LegacyTypography,
  Sizes,
  StatusVariants,
  tokens,
  TypographyVariant,
  useLegacyModal,
} from "@ttc3k/ttc-design-system";
import {
  Calendar,
  Check,
  Clock,
  InfoCircle,
  Internet,
  Label,
  Link as LinkIcon,
  Lock,
  MagicWand,
  NavArrowRight,
  WarningTriangle,
  Xmark,
} from "iconoir-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useLocation, useNavigate } from "react-router-dom";
import { omit } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import { getLatLng } from "use-places-autocomplete";
import { GoogleMaps } from "core/components/shared/GoogleMaps";
import { useAppContext } from "../../../../../core/components/apps/PreferredLayout";
import { TextArea } from "../../../../../core/components/shared/TextArea";
import { TextField } from "../../../../../core/components/shared/TextField";
import { HoursOfOperation } from "../../../../../core/components/shared/HoursOfOperation";
import { Divider } from "../../../../../core/components/shared/Divider";
import { CheckItem } from "../../../../../core/components/shared/Check";
import { Radio } from "../../../../../core/components/shared/Radio";
import { Autocomplete } from "../../../../../core/components/shared/Autocomplete";
import { handleBeaconClick } from "../../../../../core/utils/helpScout";
import { formatUnix } from "../../../../../core/utils/formatDate";
import { useCreateNotification } from "../../../../../core/hooks/useCreateNotification";
import {
  Seasonality,
  SeasonalityProps,
} from "../../../../../core/components/shared/Seasonality";
import { Select } from "../../../../../core/components/shared/Select";
import { addHttpIfNeeded } from "../../../../../core/utils/isUrl";
import { HelperText } from "../../../../../core/components/shared/HelperText";
import { Note } from "../../../../../core/components/shared/Note";
import {
  GetOperatorQuery,
  GetOperatorsDocument,
  useDeleteOperatorMutation,
  useSetOperatorAsPermanentlyClosedMutation,
} from "../../../../../gql/generated";
import { SummarizeModal } from "../../../../../core/components/shared/SummarizeModal";
import { DaysValues } from "../../../../../core/components/shared/HoursOfOperation/utils";
import { handleAsyncError } from "../../../../../core/utils/alert";
import { tagBundles } from "../../../../../core/constants/operator";
import { Dialog } from "../../../../../core/components/shared/Dialog";
import { Leaf } from "../../../../../core/components/shared/Leaf";
import { getAutocompleteAddress } from "../../../../../core/utils/getAutocompleteAddress";
import {
  getTagColorByNumber,
  useGetTags,
  useUpdateOperator,
} from "./Profile/utils";
import {
  checklistLabels,
  checklistOrder,
  maxDescriptionCharacters,
  maxShortDescriptionCharacters,
  openHoursOptionsLabels,
  openHoursOptionsOrder,
  profileDefaultValues,
  ProfileSchema,
  profileSchema,
  publishingOptionsLabels,
  publishingOptionsOrder,
  typeOfOperatorLabels,
} from "./Profile/schema";
import { profileMapper } from "./Profile/profileMapper";
import { InputLink } from "core/components/shared/InputLink";

export const LockIcon = () => {
  return (
    <Lock
      width={24}
      height={24}
      strokeWidth={2}
      color={tokens.semantic.iconSubtle}
    />
  );
};

export default function Profile({
  data,
  loading,
  setLeaf,
}: {
  data: GetOperatorQuery;
  loading: boolean;
  setLeaf: Function;
}) {
  //@ToDO Pass this variable from tenantLoader
  const navigate = useNavigate();
  const [closeOperatorModal, setCloseOperatorModal] = useState(false);
  const [deleteOperatorModal, setDeleteOperatorModal] = useState(false);

  const [showManualAddress, setShowManualAddress] = useState(false);
  const location = useLocation();
  const { user } = useAuth0();
  const tenantAdmin = user?.whereaboutsOnboarding.isAdmin;
  const { closeModal, modalOverlayRef, openModal } = useLegacyModal();

  const { id } = useParams();
  const form = useForm<ProfileSchema>({
    resolver: zodResolver(profileSchema),
    defaultValues: profileDefaultValues,
    mode: "onChange",
  });

  const [closeOperator, { loading: closeOperatorLoading }] =
    useSetOperatorAsPermanentlyClosedMutation();
  const [deleteOperator, { loading: deleteOperatorLoading }] =
    useDeleteOperatorMutation();

  const [isFormError, setIsFormError] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty },
    watch,
    setValue,
  } = form;

  const openHoursOption = watch("open_hours_option");

  const checklistValues = watch("checklist");
  const noteIsPublic = watch("note.is_public");
  const typeOfOperator = watch("tags.type_of_operator");

  const description = watch("description.en");

  const checklistValuesTotal = Object.values(
    omit(checklistValues, ["__typename"]),
  );
  const checklistValuesEnable = checklistValuesTotal.filter(item => item);

  const { setDescription } = useAppContext();

  // This can be refactored to do direct cache surgery rather the refetching query after updateOperator
  const [updateOperator, { data: saved }] = useUpdateOperator(id);
  const [createNotification] = useCreateNotification();

  const { data: tags } = useGetTags(tagBundles);

  useEffect(() => {
    setDescription("Edit your profile. Don't forget to save!");
  }, [setDescription]);

  useEffect(() => {
    if (data?.getOperator) {
      const newProfileFormObj = profileMapper(data);

      const { line_1, city, province, postal_code, address } =
        newProfileFormObj;
      if (!address) {
        newProfileFormObj["address"] = [line_1, city, province, postal_code]
          .filter(item => !!item)
          .join(", ");
      }

      reset(newProfileFormObj);
    }
  }, [data, reset]);

  const onSubmit: SubmitHandler<ProfileSchema> = async formData => {
    const submittedData = tenantAdmin
      ? formData
      : { ...formData, status: "Updated" };
    try {
      await updateOperator({
        variables: { id: id, record: submittedData },
      });
      setIsFormError(false);
    } catch (err) {
      handleAsyncError();
    }
    try {
      await createNotification({
        variables: {
          notification: JSON.stringify({
            tenant: "vtc",
            title: `${formData.operator_name} was updated`,
            message: `by ${user?.name}`,
            button: { label: "Review", path: location.pathname },
          }),
        },
      });
    } catch (err) {
      handleAsyncError();
    }
  };

  const onSubmitError: SubmitErrorHandler<
    ProfileSchema
  > = async formDataError => {
    const errors: string[] = [];
    for (const e of Object.values(formDataError)) {
      if (e && e.message) {
        errors.push(e.message);
      }
    }
    errors.length > 0 && setIsFormError(true);
  };

  const handleCloseOperator = () => {
    if (id)
      closeOperator({
        variables: { id: id },
        onCompleted: () => {
          navigate("/app/directory");
        },
        refetchQueries: [GetOperatorsDocument],
        awaitRefetchQueries: true,
      });
  };

  const handleDeleteOperator = () => {
    if (id)
      deleteOperator({
        variables: { id: id },
        onCompleted: () => {
          navigate("/app/directory");
        },
        refetchQueries: [GetOperatorsDocument],
        awaitRefetchQueries: true,
      });
  };

  const handleSelect = (arg: google.maps.GeocoderResult | null) => {
    if (!arg) {
      setValue("address", "", {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue("latitude", 0);
      setValue("longitude", 0);
      setValue("city", "");
      setValue("line_1", "");
      setValue("province", "");
      setValue("postal_code", "");
      return;
    }

    const obj = getAutocompleteAddress(arg);

    const { lat, lng } = getLatLng(arg);
    setValue("address", arg.formatted_address, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("latitude", lat);
    setValue("longitude", lng);
    setValue("city", obj.city);
    setValue("line_1", obj.line1);
    setValue("province", obj.province);
    setValue("postal_code", obj.postal_code);
  };

  const capacityLabel = typeOfOperatorLabels.get(typeOfOperator);

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <motion.main
      className={"tw-flex tw-gap-9 tw-flex-col lg:tw-flex-row tw-pb-[100px]"}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className={"tw-w-full"}>
        <form className="tw-flex tw-flex-col tw-gap-12 ">
          <div className={"tw-flex tw-flex-col tw-gap-4"}>
            {tenantAdmin && (
              <>
                <div className="tw-w-full tw-relative tw-p-9 tw-mb-9 tw-bg-white tw-rounded-xl tw-border-2 tw-border-borderWarning tw-flex-col tw-justify-start tw-items-start tw-gap-6 tw-inline-flex">
                  <div className="tw-self-stretch tw-justify-between tw-items-center tw-inline-flex">
                    <div className="tw-justify-start tw-items-center tw-gap-2 tw-flex">
                      <LegacyTypography
                        variant={TypographyVariant.h5}
                        color={tokens.semantic.textWarning}
                      >
                        Admin Zone
                      </LegacyTypography>
                    </div>
                  </div>
                  <div className="tw-self-stretch tw-h-16 tw-flex-col tw-justify-start tw-items-start tw-flex">
                    <div className="tw-self-stretch tw-h-16 tw-flex-col tw-justify-start tw-items-start tw-gap-1 tw-flex">
                      <LegacyTypography
                        variant={TypographyVariant.secondaryBold}
                        color={tokens.semantic.textSubtle}
                      >
                        Profile Status
                      </LegacyTypography>
                      <Controller
                        name="status"
                        control={control}
                        render={({ field }) => (
                          <LegacyDropdown
                            value={field.value}
                            onChange={field.onChange}
                            options={[
                              { value: "Updated", label: "Updated" },
                              { value: "Approved", label: "Approved" },
                              {
                                value: "Needs Attention",
                                label: "Needs Attention",
                              },
                            ]}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="tw-flex tw-flex-col tw-space-y-space-150 tw-w-full">
                    <LegacyTypography variant={TypographyVariant.primaryBold}>
                      Add a note
                    </LegacyTypography>
                    <LegacyTypography color={tokens.semantic.textSubtle}>
                      Write a note for this member. When they log in, they’ll
                      see it pinned here in their profile.
                    </LegacyTypography>
                    <Controller
                      name="note"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextArea
                          rows={4}
                          error={!!fieldState.error}
                          placeholder="Write your note..."
                          {...field}
                          onChange={val =>
                            field.onChange({
                              text: val.target.value,
                              by: user.name,
                              date: Math.floor(Date.now() / 1000),
                              is_public: field.value.is_public,
                            })
                          }
                          value={field.value.text}
                        />
                      )}
                    />
                    <Controller
                      name="note.is_public"
                      control={control}
                      render={({ field }) => (
                        <LegacyCheckbox
                          label="Make this note public on the member’s profile. They’ll have the ability to resolve it. "
                          onChange={() => field.onChange(!field.value)}
                          checked={field.value}
                        />
                      )}
                    />
                  </div>
                  <div className="tw-self-stretch tw-flex-col tw-justify-start tw-items-start tw-gap-3 tw-flex">
                    <LegacyTypography variant={TypographyVariant.primaryBold}>
                      Publishing Options
                    </LegacyTypography>
                    {data.getOperator?.updated_at && (
                      <LegacyTypography
                        variant={TypographyVariant.secondaryBold}
                        color={tokens.semantic.textSubtlest}
                      >
                        Last synced: {formatUnix(data.getOperator.updated_at)}
                      </LegacyTypography>
                    )}
                    <div className="tw-self-stretch tw-px-4 tw-py-3 tw-bg-bgWarningHover tw-rounded-lg tw-justify-start tw-items-start tw-gap-2.5 tw-inline-flex">
                      <LegacyTypography color={tokens.colors.y700}>
                        This profile has changes not currently live on VTC.com
                      </LegacyTypography>
                    </div>
                    {publishingOptionsOrder.map(item => {
                      const label = publishingOptionsLabels[item];
                      return (
                        <Controller
                          key={id + "_" + item}
                          name="publishingOptions"
                          control={control}
                          render={({ field }) => (
                            <Radio
                              label={label}
                              value={item}
                              name="publishingOptions"
                              onChange={field.onChange}
                              checked={field.value === item}
                            />
                          )}
                        />
                      );
                    })}
                  </div>
                </div>
              </>
            )}
            {!tenantAdmin && noteIsPublic && (
              <Controller
                name="note"
                control={control}
                render={({ field }) => {
                  if (!field.value.text) return <></>;
                  return (
                    <div className="tw-mb-space-300">
                      <Note
                        text={field.value.text}
                        by={field.value.by}
                        date={formatUnix(field.value.date)}
                        onClick={() =>
                          field.onChange({
                            text: "",
                            by: "",
                            date: 0,
                            is_public: false,
                          })
                        }
                      />
                    </div>
                  );
                }}
              />
            )}
            <div className={"tw-pb-2"}>
              <LegacySectionHeader
                title={"Business Info"}
                icon={<InfoCircle width={24} height={24} strokeWidth={2} />}
              />
            </div>
            <Controller
              name="operator_name"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Business Name"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  // endIcon={<LockIcon />}
                  // disabled
                />
              )}
            />
            <Controller
              name="website"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label={"Website"}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  endIcon={
                    !fieldState.invalid &&
                    field.value && (
                      <a href={addHttpIfNeeded(field.value)} target="_blank">
                        <LinkIcon />
                      </a>
                    )
                  }
                  {...field}
                />
              )}
            />
            <Controller
              name="phone"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={"Phone"}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label={"Email"}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                />
              )}
            />
            {!showManualAddress && (
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <GoogleMaps.PlacesAutocomplete
                    label="Search by Business Name or Address"
                    onChange={handleSelect}
                    value={field.value}
                    helperText={
                      <LegacyButton
                        size={Sizes.Small}
                        variant={ButtonTagVariant.Unstyled}
                        color={tokens.semantic.textInfo}
                        onClick={() => setShowManualAddress(true)}
                        className="tw-mt-1"
                      >
                        Enter an address manually {">"}
                      </LegacyButton>
                    }
                  />
                )}
              />
            )}

            {showManualAddress && (
              <>
                <Controller
                  name="line_1"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"Address Line 1"}
                      placeholder={"Address Line 1"}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="line_2"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"Address Line 2 (optional)"}
                      placeholder={"Address Line 2 (optional)"}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="city"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"City"}
                      placeholder={"City"}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="province"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"Province"}
                      placeholder={"Province"}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="postal_code"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"Postal Code"}
                      placeholder={"Postal Code"}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="latitude"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"Latitude"}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="longitude"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"Longitude"}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    />
                  )}
                />
              </>
            )}
            <Controller
              control={control}
              name="description.en"
              render={({ field, fieldState }) => (
                <TextArea
                  label="Description"
                  labelRight={
                    field.value.length.toString() +
                    "/" +
                    maxDescriptionCharacters
                  }
                  helperTextClassName={"tw-text-right"}
                  error={!!fieldState.error}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="short_description.en"
              render={({ field, fieldState }) => (
                <TextArea
                  label="Short Description"
                  labelRight={
                    field.value.length.toString() +
                    "/" +
                    maxShortDescriptionCharacters
                  }
                  error={!!fieldState.error}
                  bottomAdornment={
                    <LegacyButton
                      size={Sizes.Small}
                      variant={ButtonTagVariant.Unstyled}
                      color={"#7979EC"}
                      className="tw-self-end"
                      onClick={openModal}
                      iconStart
                      icon={<MagicWand fontSize={14} />}
                      disabled={!description}
                    >
                      <span>Auto-generate with AI...</span>
                    </LegacyButton>
                  }
                  {...field}
                />
              )}
            />
            <Controller
              name="more_info_link"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label="Link to more info (optional)"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  endIcon={
                    !fieldState.invalid &&
                    field.value && <InputLink url={field.value} />
                  }
                  {...field}
                />
              )}
            />
            <Controller
              name="sta_license_status"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label={"STA License Status"}
                  error={!!fieldState.error}
                  helperText={
                    "If your business listing includes a short-term accommodation, you must have a valid STA license in order to be listed on the site. The info you provide will be verified with the STA Licensing database. Please list your license number, or use this space to inform us of your STA license application status."
                  }
                  {...field}
                />
              )}
            />
          </div>

          <div className={"tw-flex tw-flex-col tw-gap-4 tw-font-figtree"}>
            <div className={"tw-pb-2"}>
              <LegacySectionHeader
                title={"Tags"}
                icon={<Label width={24} height={24} strokeWidth={2} />}
              />
            </div>
            <LegacyToast variant={StatusVariants.Info}>
              Please select the tags that are the most relevant to your
              business. These tags help us understand your business and pick the
              appropriate marketing channels.
            </LegacyToast>
            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-[12px]">
              <Controller
                name="tags.type_of_operator"
                control={control}
                render={({ field }) => (
                  <Select
                    label="Business Type"
                    value={field.value}
                    onChange={e => {
                      field.onChange(e);
                      setValue("tags.capacity", 0);
                    }}
                    options={
                      tags &&
                      tags.type_of_operator &&
                      tags.type_of_operator.length > 0
                        ? tags.type_of_operator.map(item => ({
                            label: item?.label?.en ? item?.label?.en : "",
                            value: item._id ? item._id : "",
                          }))
                        : []
                    }
                    helperText="Please select your main business type. You'll have a chance to
                      add more tags below."
                  />
                )}
              />
              {capacityLabel && (
                <Controller
                  name="tags.capacity"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      type="number"
                      min={0}
                      label={capacityLabel}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    />
                  )}
                />
              )}
            </div>
            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-[12px]">
              <Controller
                name="tags.pricing"
                control={control}
                render={({ field }) => (
                  <Select
                    label="Pricing"
                    value={field.value}
                    onChange={field.onChange}
                    options={
                      tags && tags.pricing && tags.pricing.length > 0
                        ? tags.pricing.map(item => ({
                            label: item?.label?.en ? item?.label?.en : "",
                            value: item._id ? item._id : "",
                          }))
                        : []
                    }
                    helperText="Compared to other similar businesses in the region, what should your customers expect to spend?"
                  />
                )}
              />
            </div>
            <Controller
              name="tags.region"
              control={control}
              render={({ field }) => {
                return (
                  <Autocomplete
                    label="Region, Sub-Region, or Ward"
                    options={
                      tags && tags.region && tags.region.length > 0
                        ? tags.region.map(item => ({
                            label: item?.label?.en ? item?.label?.en : "",
                            value: item._id ? item._id : "",
                          }))
                        : []
                    }
                    value={field.value ?? []}
                    onChange={field.onChange}
                    helperText={
                      <a
                        href="https://www.thecounty.ca/wp-content/uploads/2020/08/Current-Ten-Ward-Map.pdf"
                        target="_blank"
                      >
                        <HelperText
                          text="Please click here to find your ward."
                          className="tw-underline"
                        />
                      </a>
                    }
                  />
                );
              }}
            />
            <Controller
              name="tags.accreditations"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  label="Accreditations & Associations"
                  options={
                    tags && tags.accreditation && tags.accreditation.length > 0
                      ? tags.accreditation.map(item => ({
                          label: item?.label?.en ? item?.label?.en : "",
                          value: item._id ? item._id : "",
                        }))
                      : []
                  }
                  value={field.value ?? []}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              name="tags.amenities"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  label="Tags"
                  options={
                    tags && tags.amenities && tags.amenities.length > 0
                      ? tags.amenities.map(item => ({
                          label: item?.label?.en ? item?.label?.en : "",
                          value: item._id ? item._id : "",
                        }))
                      : []
                  }
                  value={field.value}
                  onChange={field.onChange}
                  helperText="Please select as many as are relevant. These tags help us understand your business and pick the appropriate marketing channels."
                />
              )}
            />

            {tenantAdmin && (
              <div>
                <Controller
                  name="tags.vtc_custom"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      options={
                        tags && tags.custom_vtc && tags.custom_vtc.length > 0
                          ? tags.custom_vtc.map(item => ({
                              label: item?.label?.en ? item?.label?.en : "",
                              value: item._id ? item._id : "",
                            }))
                          : []
                      }
                      label="Admin tags"
                      value={field.value}
                      onChange={field.onChange}
                      tagPropsVariants="warning"
                      helperText="These tags are only visible to VTC."
                    />
                  )}
                />
              </div>
            )}

            <LegacyButton
              iconStart
              icon={<InfoCircle strokeWidth={2} />}
              variant={ButtonTagVariant.Unstyled}
              color={tokens.semantic.textInfo}
              className={"tw-text-left"}
              onClick={() =>
                handleBeaconClick(user, "Suggestions for new Tags")
              }
            >
              Would you like to add Accreditations or have suggestions for new
              Tags? Please click here to send us a suggestion!
            </LegacyButton>
          </div>

          <div className={"tw-flex tw-flex-col tw-gap-4"}>
            <div className={"tw-pb-2"}>
              <LegacySectionHeader
                title={"Social Links"}
                icon={<Internet width={24} height={24} strokeWidth={2} />}
              />
            </div>
            <Controller
              name="facebook_link"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label={"Facebook"}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  endIcon={
                    !fieldState.invalid &&
                    field.value && (
                      <a href={addHttpIfNeeded(field.value)} target="_blank">
                        <LinkIcon />
                      </a>
                    )
                  }
                  {...field}
                />
              )}
            />
            <Controller
              name="instagram_link"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label={"Instagram"}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  endIcon={
                    !fieldState.invalid &&
                    field.value && (
                      <a href={addHttpIfNeeded(field.value)} target="_blank">
                        <LinkIcon />
                      </a>
                    )
                  }
                  {...field}
                />
              )}
            />
            <Controller
              name="youtube_link"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label={"Youtube"}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  endIcon={
                    !fieldState.invalid &&
                    field.value && (
                      <a href={addHttpIfNeeded(field.value)} target="_blank">
                        <LinkIcon />
                      </a>
                    )
                  }
                  {...field}
                />
              )}
            />
            <Controller
              name="pinterest_link"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label={"Pinterest"}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  endIcon={
                    !fieldState.invalid &&
                    field.value && (
                      <a href={addHttpIfNeeded(field.value)} target="_blank">
                        <LinkIcon />
                      </a>
                    )
                  }
                  {...field}
                />
              )}
            />
            <Controller
              name="tiktok_link"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label={"TikTok"}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  endIcon={
                    !fieldState.invalid &&
                    field.value && (
                      <a href={addHttpIfNeeded(field.value)} target="_blank">
                        <LinkIcon />
                      </a>
                    )
                  }
                  {...field}
                />
              )}
            />
            <Controller
              name="google_mybusiness_link"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label={"Google MyBusiness Profile"}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  endIcon={
                    !fieldState.invalid &&
                    field.value && (
                      <a href={addHttpIfNeeded(field.value)} target="_blank">
                        <LinkIcon />
                      </a>
                    )
                  }
                  {...field}
                />
              )}
            />
            <Controller
              name="tripadvisor_link"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label={"Trip Advisor Link"}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  endIcon={
                    !fieldState.invalid &&
                    field.value && (
                      <a href={addHttpIfNeeded(field.value)} target="_blank">
                        <LinkIcon />
                      </a>
                    )
                  }
                  {...field}
                />
              )}
            />
          </div>

          <div className={"tw-flex tw-flex-col tw-gap-4"}>
            <LegacySectionHeader
              title={"Seasonality"}
              icon={<Calendar width={24} height={24} strokeWidth={2} />}
            />

            <LegacyTypography color={tokens.semantic.textSubtle}>
              Please let us know your high/low seasons. This helps us immensely
              in planning our marketing campaigns. For all rows, simply select
              any number of corresponding months.
            </LegacyTypography>
            <Controller
              name="seasonality"
              control={control}
              render={({ field }) => (
                <Seasonality
                  value={field.value as SeasonalityProps["value"]}
                  onChange={field.onChange}
                />
              )}
            />
          </div>

          <div className={"tw-flex tw-flex-col tw-gap-4"}>
            <div className={"tw-pb-2"}>
              <LegacySectionHeader
                title={"Hours of Operation"}
                icon={<Clock width={24} height={24} strokeWidth={2} />}
              />
            </div>
            <div className="tw-flex tw-flex-col tw-gap-y-[12px]">
              {openHoursOptionsOrder.map(item => {
                let label = openHoursOptionsLabels[item];
                const has247 = label.includes("24/7");
                if (has247) label = label.replace("24/7", "");
                return (
                  <Controller
                    key={id + "_" + item}
                    name="open_hours_option"
                    control={control}
                    render={({ field }) => (
                      <Radio
                        label={
                          <>
                            {label}
                            {has247 && <strong>24/7</strong>}
                          </>
                        }
                        value={item}
                        name="open_hours_option"
                        onChange={field.onChange}
                        checked={field.value === item}
                      />
                    )}
                  />
                );
              })}
            </div>
            {openHoursOption === "opening_hours_specified" && (
              <>
                <LegacyTypography color={tokens.semantic.textSubtle}>
                  Start by selecting the days of the week you’re open, then
                  customize your opening hours for each day.{" "}
                </LegacyTypography>
                <Controller
                  name="hours_of_operation_week"
                  control={control}
                  render={({ field }) => (
                    <HoursOfOperation
                      value={field.value as DaysValues}
                      onChange={field.onChange}
                    />
                  )}
                />
                <div className="tw-self-stretch tw-h-16 tw-flex-col tw-justify-start tw-items-start tw-gap-1 tw-flex">
                  <LegacyTypography
                    variant={TypographyVariant.secondaryMedium}
                    color={tokens.semantic.textSubtle}
                  >
                    Where can visitors find your most up-to-date opening hours?
                  </LegacyTypography>
                  <Controller
                    name="up_to_date_source"
                    control={control}
                    render={({ field }) => (
                      <LegacyDropdown
                        value={field.value}
                        onChange={field.onChange}
                        options={[
                          {
                            value: "google_my_business",
                            label: "Google My Business",
                          },
                          { value: "instagram", label: "Instagram" },
                          { value: "my_website", label: "My Website" },
                          { value: "facebook", label: "Facebook" },
                        ]}
                      />
                    )}
                  />
                </div>
              </>
            )}
          </div>
        </form>

        {tenantAdmin && (
          <Leaf.Section
            className="tw-mt-16 tw-mb-16"
            title="Danger Zone"
            icon={<WarningTriangle width={24} height={24} strokeWidth={2} />}
          >
            <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-x-8 tw-gap-y-4 tw-items-center">
              <div className="tw-flex-1 tw-flex tw-flex-col tw-gap-1">
                <LegacyTypography variant={TypographyVariant.primaryBold}>
                  Close Business
                </LegacyTypography>
                <LegacyTypography
                  variant={TypographyVariant.secondaryMedium}
                  color={tokens.semantic.textSubtlest}
                >
                  Mark this business as permanently closed
                </LegacyTypography>
              </div>
              <div>
                <LegacyButton
                  icon={
                    <NavArrowRight width={24} height={24} strokeWidth={2} />
                  }
                  variant={ButtonTagVariant.ErrorBold}
                  onClick={() => setCloseOperatorModal(true)}
                >
                  <LegacyTypography className="tw-whitespace-nowrap">
                    Permanently close
                  </LegacyTypography>
                </LegacyButton>
              </div>
            </div>
            <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-x-8 tw-gap-y-4 tw-items-center">
              <div className="tw-flex-1 tw-flex tw-flex-col tw-gap-1">
                <LegacyTypography variant={TypographyVariant.primaryBold}>
                  Delete Business
                </LegacyTypography>
              </div>
              <div>
                <LegacyButton
                  icon={
                    <NavArrowRight width={24} height={24} strokeWidth={2} />
                  }
                  variant={ButtonTagVariant.ErrorBold}
                  // isLoading={deleteEventLoading}
                  onClick={() => setDeleteOperatorModal(true)}
                >
                  <LegacyTypography className="tw-whitespace-nowrap">
                    Delete Business
                  </LegacyTypography>
                </LegacyButton>
              </div>
            </div>
          </Leaf.Section>
        )}
      </div>

      {/* Checklist */}
      <div className="tw-relative">
        <div className="tw-sticky tw-top-[122px] tw-w-[320px] tw-shadow-[4px_4px_0_0_#CDC9C2] max-lg:tw-w-full">
          {/* Profile Checklist */}
          <div className="tw-bg-white tw-p-[32px] tw-flex tw-flex-col tw-gap-y-[20px]">
            <div className="tw-flex tw-flex-col tw-gap-y-[20px]">
              <div className="tw-flex tw-justify-between tw-items-center">
                <LegacyTypography variant={TypographyVariant.secondaryBold}>
                  Profile Checklist
                </LegacyTypography>
                <LegacyTag
                  variant={getTagColorByNumber(
                    checklistValuesEnable.length,
                    checklistValuesTotal.length,
                  )}
                >
                  {`${checklistValuesEnable.length}/${checklistValuesTotal.length}`}
                  <Check strokeWidth={2} height={20} width={20} />
                </LegacyTag>
              </div>
              <LegacyTypography
                variant={TypographyVariant.secondaryRegular}
                color={tokens.semantic.textSubtlest}
              >
                A few friendly reminders. Please check them off as you complete
                them.
              </LegacyTypography>
            </div>
            <Divider />
            <div className="tw-flex tw-flex-col tw-gap-y-[12px]">
              {checklistOrder.map(item => {
                const label = checklistLabels[item];
                return (
                  <Controller
                    key={id + "_" + item}
                    name={`checklist.${item}`}
                    control={control}
                    render={({ field }) => (
                      <CheckItem
                        label={label}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                );
              })}
            </div>
            <div className="tw-flex tw-flex-col tw-gap-y-[12px]">
              <LegacyButton
                variant={
                  saved && !isDirty
                    ? ButtonTagVariant.SuccessBold
                    : ButtonTagVariant.InfoSubdued
                }
                fullWidth
                onClick={() => handleSubmit(onSubmit, onSubmitError)()}
                disabled={!isDirty || isSubmitting || loading}
              >
                {saved && !isDirty ? "Saved" : "Save"}
              </LegacyButton>
              {isFormError && (
                <LegacyToast variant={StatusVariants.Error}>
                  Error: One or more fields have an error. Please check and try
                  again.
                </LegacyToast>
              )}
              {saved && !isFormError && (
                <>
                  {data?.getOperator.status == "Approved" ? (
                    <LegacyToast>
                      Thank you! We are reviewing these changes.
                    </LegacyToast>
                  ) : (
                    <>
                      <LegacyToast>
                        Thank you! We are reviewing these changes.
                      </LegacyToast>
                      {/*<LegacyTypography>*/}
                      {/*  When you're ready, please head to{" "}*/}
                      {/*  <strong>Connections</strong> to submit your listing for*/}
                      {/*  approval.*/}
                      {/*</LegacyTypography>*/}
                      {/*<LegacyButton onClick={() => setLeaf("CONNECTIONS")}>*/}
                      {/*  <Antenna strokeWidth={2} width={18} height={18} />*/}
                      {/*  Connections*/}
                      {/*</LegacyButton>*/}
                    </>
                  )}
                </>
              )}
              <LegacyTypography
                variant={TypographyVariant.tertiaryMedium}
                color={tokens.semantic.textSubtlest}
              >
                Please{" "}
                <button
                  className="tw-underline tw-text-textInfo tw-bg-transparent"
                  onClick={() => handleBeaconClick(user)}
                >
                  contact us
                </button>{" "}
                if you have any concerns
              </LegacyTypography>
            </div>
          </div>
        </div>
      </div>
      <FormProvider {...form}>
        <SummarizeModal<ProfileSchema>
          modalOverlayRef={modalOverlayRef}
          onModalClose={closeModal}
          inputField={"description.en"}
          outputField={"short_description.en"}
        />
      </FormProvider>

      {/* PermanentlyClosed modal */}
      <Dialog
        isOpen={closeOperatorModal}
        onClose={() => setCloseOperatorModal(false)}
      >
        <div className="tw-flex tw-justify-between tw-mb-4">
          <LegacyTypography variant={TypographyVariant.h5}>
            Permanently close this business
          </LegacyTypography>
          <LegacyButton
            variant={ButtonTagVariant.Unstyled}
            color={tokens.semantic.iconSubtlest}
            icon={<Xmark strokeWidth={2} height={24} width={24} />}
            onClick={() => setCloseOperatorModal(false)}
          />
        </div>
        <LegacyTypography color={tokens.semantic.textSubtlest}>
          Permanently closing this business will tag it as closed in our
          database. You can re-open this business by clicking the “Add a
          business” button in the Directory app.
        </LegacyTypography>
        <div className="tw-w-full tw-flex tw-justify-between tw-gap-3 tw-mt-9">
          <LegacyButton
            variant={ButtonTagVariant.Secondary}
            onClick={() => setCloseOperatorModal(false)}
            type="button"
            fullWidth
          >
            Cancel
          </LegacyButton>
          <LegacyButton
            variant={ButtonTagVariant.ErrorBold}
            onClick={handleCloseOperator}
            type="button"
            fullWidth
            isLoading={closeOperatorLoading}
          >
            Close business
          </LegacyButton>
        </div>
      </Dialog>

      {/* Delete modal */}
      <Dialog
        isOpen={deleteOperatorModal}
        onClose={() => setDeleteOperatorModal(false)}
      >
        <div className="tw-flex tw-justify-between tw-mb-4">
          <LegacyTypography variant={TypographyVariant.h5}>
            Delete this business
          </LegacyTypography>
          <LegacyButton
            variant={ButtonTagVariant.Unstyled}
            color={tokens.semantic.iconSubtlest}
            icon={<Xmark strokeWidth={2} height={24} width={24} />}
            onClick={() => setDeleteOperatorModal(false)}
          />
        </div>
        <LegacyTypography color={tokens.semantic.textSubtlest}>
          Warning! If you continue, you will delete this operator from the
          database. It cannot be undone.
        </LegacyTypography>
        <div className="tw-w-full tw-flex tw-justify-between tw-gap-3 tw-mt-9">
          <LegacyButton
            variant={ButtonTagVariant.Secondary}
            onClick={() => setDeleteOperatorModal(false)}
            type="button"
            fullWidth
          >
            Cancel
          </LegacyButton>
          <LegacyButton
            variant={ButtonTagVariant.ErrorBold}
            onClick={handleDeleteOperator}
            type="button"
            fullWidth
            isLoading={deleteOperatorLoading}
          >
            Delete business
          </LegacyButton>
        </div>
      </Dialog>
    </motion.main>
  );
}
